import React from 'react';
import Layout from '../../layout';
// import './PlayersPage.scss';

const PlayersPage = () => (
  <Layout title="WIP - players" className="not-found">
    <div className="not-found-title">
      <video style={{ width: '100%', maxWidth: '800px' }} controls className="my-5">
        <source src="/videos/test1.mp4" type="video/mp4" />
        <track src="missing.vtt" kind="captions" srcLang="en" label="English" />
      </video>
    </div>
  </Layout>
);

export default PlayersPage;
